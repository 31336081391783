import { subjectAbility } from '@/constant'
/*
 * pageTitle : get from @/libs/i18n/locales/[id / en].json
 * in purpose to support multi language
 */

export default [
  {
    path: '/report/ledger',
    name: 'apps-report-ledger',
    component: () => import('@/views/apps/report/Ledger.vue'),
    meta: {
      pageTitle: 'apps.reportLedger.moduleName',
      action: 'read',
      resource: subjectAbility.ReportLedger
    }
  },
  {
    path: '/report/journal',
    name: 'apps-report-journal',
    component: () => import('@/views/apps/report/Journal.vue'),
    meta: {
      pageTitle: 'apps.reportJournal.moduleName',
      action: 'read',
      resource: subjectAbility.ReportJournal
    }
  },
  {
    path: '/report/journalreport',
    name: 'apps-report-journal-list',
    component: () => import('@/views/apps/report/Journal.vue'),
    meta: {
      pageTitle: 'apps.reportJournal.moduleName',
      action: 'read',
      resource: subjectAbility.ReportJournal
    }
  },
  {
    path: '/report/trial-balance',
    name: 'apps-report-trial-balance',
    component: () => import('@/views/apps/report/TrialBalance.vue'),
    meta: {
      pageTitle: 'apps.reportTrialBalance.moduleName',
      action: 'read',
      resource: subjectAbility.ReportTrialBalance
    }
  },
  {
    path: '/report/work-sheet',
    name: 'apps-report-work-sheet',
    component: () => import('@/views/apps/report/WorkSheet.vue'),
    meta: {
      pageTitle: 'apps.reportWorkSheet.moduleName',
      action: 'read',
      resource: subjectAbility.ReportWorkSheet
    }
  },
  {
    path: '/report/payment',
    name: 'apps-report-payment',
    component: () => import('@/views/apps/report/Payment.vue'),
    meta: {
      pageTitle: 'apps.reportPayment.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPayment
    }
  },
  {
    path: '/report/paymentNew',
    name: 'apps-report-payment-New',
    component: () => import('@/views/apps/report/PaymentNew.vue'),
    meta: {
      pageTitle: 'apps.reportPaymentNew.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPayment
    }
  },
  {
    path: '/report/sales-aging',
    name: 'apps-report-sales-aging',
    component: () => import('@/views/apps/report/SalesAging.vue'),
    meta: {
      pageTitle: 'apps.reportSalesAging.moduleName',
      action: 'read',
      resource: subjectAbility.ReportSalesAging
    }
  },
  {
    path: '/report/sales-aging-detail',
    name: 'apps-report-sales-aging-detail',
    component: () => import('@/views/apps/report/SalesAgingDetail.vue'),
    meta: {
      pageTitle: 'apps.reportSalesAgingDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportSalesAgingDetail
    }
  },
  {
    path: '/report/balance-sheet',
    name: 'apps-report-balance-sheet',
    component: () => import('@/views/apps/report/BalanceSheet.vue'),
    meta: {
      pageTitle: 'apps.reportBalanceSheet.moduleName',
      action: 'read',
      resource: subjectAbility.ReportBalanceSheet
    }
  },
  {
    path: '/report/balance-sheet-comparison',
    name: 'apps-report-balance-sheet-comparison',
    component: () => import('@/views/apps/report/BalanceSheetComparison.vue'),
    meta: {
      pageTitle: 'apps.reportBalanceSheet.moduleName',
      action: 'read',
      resource: subjectAbility.ReportBalanceSheetComparison
    }
  },
  {
    path: '/report/estimation-collection',
    name: 'apps-report-estimation-collection',
    component: () => import('@/views/apps/report/EstimationCollection.vue'),
    meta: {
      pageTitle: 'apps.reportEstimationCollection.singular.payment',
      action: 'read',
      resource: subjectAbility.Reportesimation
    }
  },
  {
    path: '/report/report-budget',
    name: 'apps-report-report-budget',
    component: () => import('@/views/apps/report/ReportBudget.vue'),
    meta: {
      pageTitle: 'apps.reportBudget.moduleName',
      action: 'read',
      resource: subjectAbility.ReportBalanceSheet
    }
  },
  {
    path: '/report/report-budget-month',
    name: 'apps-report-report-budget-monthly',
    component: () => import('@/views/apps/report/ReportBudgetMonthly.vue'),
    meta: {
      pageTitle: 'apps.reportBudget.moduleName',
      action: 'read',
      resource: subjectAbility.ReportBudgetMonth
    }
  },
  {
    path: '/report/profit-loss-v2',
    name: 'apps-report-report-loss-v2',
    component: () => import('@/views/apps/report/ReportProfitLoss.vue'),
    meta: {
      pageTitle: 'apps.reportProfitLossComparison.moduleName',
      action: 'read',
      resource: subjectAbility.ReportActualBudget
    }
  },
  {
    path: '/report/audit',
    name: 'apps-report-audit',
    component: () => import('@/views/apps/report/ReportAudit.vue'),
    meta: {
      pageTitle: 'apps.reportAudit.moduleName',
      action: 'read',
      resource: subjectAbility.ReportActualStd
    }
  },
  {
    path: '/report/balance-sheet',
    name: 'apps-report-balance-sheet-per-branch',
    component: () => import('@/views/apps/report/BalanceSheet.vue'),
    meta: {
      pageTitle: 'apps.reportBalanceSheetPerBranch.moduleName',
      action: 'read',
      resource: subjectAbility.ReportBalanceSheetPerBranch
    }
  },
  {
    path: '/report/cash-advance',
    name: 'apps-report-cash-advance',
    component: () => import('@/views/apps/report/CashAdvance.vue'),
    meta: {
      pageTitle: 'apps.reportCashAdvance.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashAdvance
    }
  },
  //history payment
  {
    path: '/report/history-payment',
    name: 'apps-report-history-payment',
    component: () => import('@/views/apps/report/HistoryPayment.vue'),
    meta: {
      pageTitle: 'apps.reportHistoryPayment.moduleName',
      action: 'read',
      resource: subjectAbility.ReportHistoryPayment
    }
  },
  //Master Pr
  {
    path: '/report/master-pr',
    name: 'apps-report-master-pr',
    component: () => import('@/views/apps/report/MasterPR.vue'),
    meta: {
      pageTitle: 'apps.reportMasterPR.moduleName',
      action: 'read',
      resource: subjectAbility.ReportMsterPR
    }
  },
  {
    path: '/report/cash-advance/detail',
    name: 'apps-report-cash-advance-detail',
    component: () => import('@/views/apps/report/CashAdvanceDetail.vue'),
    meta: {
      pageTitle: 'apps.reportCashAdvanceDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashAdvanceDetail
    }
  },
  {
    path: '/report/sales/invoice',
    name: 'apps-report-sales-invoice',
    component: () => import('@/views/apps/report/SalesInvoice.vue'),
    meta: {
      pageTitle: 'apps.reportSalesInvoice.moduleName',
      action: 'read',
      resource: subjectAbility.ReportSalesInvoice
    }
  },
  {
    path: '/report/cash-bank',
    name: 'apps-report-cash-bank',
    component: () => import('@/views/apps/report/CashBank.vue'),
    meta: {
      pageTitle: 'apps.reportCashBank.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashBank
    }
  },
  {
    path: '/report/cash-bank-summary',
    name: 'apps-report-cash-bank-summary',
    component: () => import('@/views/apps/report/CashbankSummary.vue'),
    meta: {
      pageTitle: 'apps.reportCashBankSummary.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashBankSummary
    }
  },
  {
    path: '/report/ch-ap-checkpoint',
    name: 'apps-report-ch_ap',
    component: () => import('@/views/apps/report/chAp.vue'),
    meta: {
      pageTitle: 'apps.reportCH.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCHCheckPoint
    }
  },
  {
    path: '/report/ch-ar-checkpoint',
    name: 'apps-report-ch_ar',
    component: () => import('@/views/apps/report/chAr.vue'),
    meta: {
      pageTitle: 'apps.reportAR.moduleName',
      action: 'read',
      resource: subjectAbility.ReportARCheckPointAr
    }
  },
  {
    path: '/monitoring/agent',
    name: 'apps-monitoring-agent',
    component: () => import('@/views/apps/report/MonitoringAgent.vue'),
    meta: {
      pageTitle: 'apps.monitoringAgent.moduleName',
      action: 'read',
      resource: subjectAbility.MonitoringAgents
    }
  },
  {
    path: '/monitoring/agentYear',
    name: 'apps-monitoring-agentYear',
    component: () => import('@/views/apps/report/monitoringAgentYear.vue'),
    meta: {
      pageTitle: 'apps.monitoringAgentYear.moduleName',
      action: 'read',
      resource: subjectAbility.MonitoringAgent
    }
  },
  {
    path: '/report/cash-and-bank',
    name: 'apps-report-cash-and-bank',
    component: () => import('@/views/apps/report/CashAndBank.vue'),
    meta: {
      pageTitle: 'apps.reportCasAndhBank.moduleName',
      action: 'read',
      resource: subjectAbility.ReportCashAndBank
    }
  },
  {
    path: '/report/cash-and-bank-report',
    name: 'apps-report-cash-and-bank-report',
    component: () => import('@/views/apps/report/CashAndBankReport.vue'),
    meta: {
      pageTitle: 'apps.reportCasAndhBank.moduleNameCashBank',
      action: 'read',
      resource: subjectAbility.ReportDetailCashbank
    }
  },
  {
    path: '/report/purchase/aging',
    name: 'apps-report-purchase-aging',
    component: () => import('@/views/apps/report/PurchaseAging.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseAging.moduleName',
      action: 'read',
      resource: subjectAbility.ReportAgingPr
    }
  },
  {
    path: '/report/purchase/aging/ch',
    name: 'apps-report-purchase-agingCH',
    component: () => import('@/views/apps/report/ReportAgingCH.vue'),
    meta: {
      pageTitle: 'apps.reportAgingCH.moduleName',
      action: 'read',
      resource: subjectAbility.ReportAgingChV1
    }
  },
  {
    path: '/report/purchase/summary/ch',
    name: 'apps-report-purchase-summaryCh',
    component: () => import('@/views/apps/report/ReportSummaryCH.vue'),
    meta: {
      pageTitle: 'apps.reportSummaryCH.singular.report',
      action: 'read',
      resource: subjectAbility.ReportSummarryCH
    }
  },
  {
    path: '/report/purchase/summary/ch_bacc',
    name: 'apps-report-purchase-summaryChBACC',
    component: () => import('@/views/apps/report/ReportSummaryCHBACC.vue'),
    meta: {
      pageTitle: 'apps.reportSummaryCHBACC.singular.report',
      action: 'read',
      resource: subjectAbility.ReportSummaryCHBACC
    }
  },
  {
    path: '/report/purchase/summary/chBadPayor',
    name: 'apps-report-purchase-summarychBadPayor',
    component: () => import('@/views/apps/report/SummaryReport.vue'),
    meta: {
      pageTitle: 'apps.reportSummary.moduleName',
      action: 'read',
      resource: subjectAbility.ReportChBadPayor
    }
  },
  {
    path: '/report/purchase/aging/estimation',
    name: 'apps-report-purchase-aging-estimation',
    component: () => import('@/views/apps/report/PurchaseAgingEstimation.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseAgingEstimation.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseAging
    }
  },
  {
    path: '/report/purchase/aging-detail',
    name: 'apps-report-purchase-aging-detail',
    component: () => import('@/views/apps/report/PurchaseAgingDetail.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseAgingDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseAgingDetail
    },
    props: route => ({
      dateUntil: route.query.dateUntil,
      contactId: route.query.contactId,
      tags: route.query.tags
    })
  },
  //report aging payment details
  {
    path: '/report/payment/aging-detail',
    name: 'apps-report-payment-aging-detail',
    component: () => import('@/views/apps/report/PaymentAgingDetail.vue'),
    meta: {
      pageTitle: 'apps.reportPaymentAgingDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPaymentAgingDetail
    },
    props: route => ({
      dateUntil: route.query.dateUntil,
      contactId: route.query.contactId,
      tags: route.query.tags
    })
  },
  // Tracking
  {
    path: '/tracking',
    name: 'apps-tracking',
    component: () => import('@/views/apps/report/Tracking.vue'),
    meta: {
      pageTitle: 'apps.tracking.moduleName',
      action: 'read',
      resource: subjectAbility.Trackings
    },
    props: route => ({
      dateUntil: route.query.dateUntil,
      contactId: route.query.contactId,
      tags: route.query.tags
    })
  },
  {
    path: '/tracking/detail/:id',
    name: 'apps-report-tracking-resi',
    component: () => import('@/views/apps/report/Tracking.vue'),
    meta: {
      pageTitle: 'apps.tracking.moduleName',
      action: 'read',
      resource: subjectAbility.Trackings
    }
  },
  {
    path: '/report/purchase/invoice',
    name: 'apps-report-purchase-invoice',
    component: () => import('@/views/apps/report/PurchaseInvoice.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseInvoice.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseInvoice
    }
  },
  // Payment Estimation
  {
    path: '/report/payment/estimation',
    name: 'apps-report-payment-estimation',
    component: () => import('@/views/apps/report/PurchasePaymentEstimation.vue'),
    meta: {
      pageTitle: 'apps.reportPaymentEstimation.moduleName',
      action: 'read',
      resource: subjectAbility.TxPaymentEstimationReports
    }
  },
  // BUDGETING
  // {
  //   path: '/report/budgeting',
  //   name: 'apps-report-budgeting',
  //   component: () => import('@/views/apps/report/Budgeting.vue'),
  //   meta: {
  //     pageTitle: 'apps.reportBudgeting.moduleName',
  //     action: 'read',
  //     resource: subjectAbility.ReportBudgeting
  //   }
  // },
  {
    path: '/report/purchase/invoice-detail',
    name: 'apps-report-purchase-invoice-detail',
    component: () => import('@/views/apps/report/PurchaseInvoiceDetail.vue'),
    meta: {
      pageTitle: 'apps.reportPurchaseInvoiceDetail.moduleName',
      action: 'read',
      resource: subjectAbility.ReportPurchaseInvoiceDetail
    },
    props: route => ({
      dateUntil: route.query.dateUntil,
      contactId: route.query.contactId,
      type: route.query.type,
      tags: route.query.tags
    })
  },
  {
    path: '/report/profit-loss',
    name: 'apps-report-profit-loss',
    component: () => import('@/views/apps/report/ProfitLoss.vue'),
    meta: {
      pageTitle: 'apps.reportProfitLoss.moduleName',
      action: 'read',
      resource: subjectAbility.ReportProfitLoss
    }
  }
]
